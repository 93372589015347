import React from 'react'
import Layout from '../components/layout'
import {
    Container
} from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import PaperBackground from '../components/PaperBackground/PaperBackground'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

const Post = ({ data, pageContext }) => {
    
// Get pageContext object from gatsby-node.js
const { prev, next } = pageContext

const document = data.prismicBlogPost.data

  return (
    <Layout>
        <PaperBackground>
            <Container className="p-4">
                <h2 className="sectionTitle">{document.post_title.text}</h2>
                <p className="text-center">
                    {`Published ${document.published_date}`}
                </p>
                <div className="mb-4"><GatsbyImage image={getImage(document.featured_image.localFile)} alt={document.featured_image.alt} /></div>
                <div>
                    <RichText render={document.post_content.raw} />
                </div>
                <div className="d-flex" style={{
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                  <div>
                    {prev && (
                      <Link
                        to={`/blog/${prev.uid}`}
                        variant="blog-link"
                        style={{
                            fontSize: '18px',
                        }}
                      >
                        {/* <ChevronLeftIcon fontSize="1.5rem" /> */}
                        <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{
                        fontSize: '16px',
                    }}
                    aria-hidden="true"
                />{' '}{prev.data.post_title.text}
                      </Link>
                    )}
                  </div>
                  <div>
                    {next && (
                      <Link
                        to={`/blog/${next.uid}`}
                        variant="blog-link"
                        style={{
                            fontSize: '18px',
                        }}
                      >
                        {next.data.post_title.text}{' '}<FontAwesomeIcon
                    icon={faChevronRight}
                    style={{
                        fontSize: '16px',
                    }}
                    aria-hidden="true"
                />
                        {/* <ChevronRightIcon fontSize="1.5rem" /> */}
                      </Link>
                    )}
                  </div>
                </div>
              
            </Container>
        </PaperBackground>

      
    </Layout>
  )
}

export const query = graphql`
query BlogPostQuery($uid: String)
{
    prismicBlogPost(uid: {eq: $uid}) {
      uid
      data {
        published_date(formatString: "MMMM, D YYYY")
        post_title {
          text
        }
        post_excerpt {
          text
        }
        post_content {
          raw
        }
        featured_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.5, layout: FULL_WIDTH, placeholder: BLURRED, transformOptions: {cropFocus: CENTER})
            }
          }
        }
      }
    }
  }
`

export default Post;
